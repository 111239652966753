import Vue from "vue";
import App from "./App.vue";
import router from "./router/index";

import Flicking from "@egjs/vue-flicking";
import "@egjs/vue-flicking/dist/flicking.css";
// Or, if you have to support IE9
import "@egjs/vue-flicking/dist/flicking-inline.css";

Vue.use(Flicking);

//Vue Toast
//======================================================
import "vue-toast-notification/dist/theme-default.css";
import VueToast from "vue-toast-notification";
Vue.use(VueToast, {
  position: "top-right",
});

//Vue Mask
//======================================================
import VueTheMask from "vue-the-mask";
Vue.use(VueTheMask);

//Vue SmoothScroll
//======================================================
import vueSmoothScroll from "vue2-smooth-scroll";
Vue.use(vueSmoothScroll);

//Vue Bootstrap
//======================================================
import { BootstrapVue } from "bootstrap-vue";
Vue.use(BootstrapVue);
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

//VeeValidate
//======================================================
import {
  localize,
  extend,
  ValidationProvider,
  ValidationObserver,
  configure,
} from "vee-validate";
import {
  required,
  email,
  min,
  max,
  confirmed,
  min_value,
  max_value,
  alpha,
  alpha_dash,
  alpha_spaces,
} from "vee-validate/dist/rules";
extend("confirmed", confirmed);
extend("min", min);
extend("max", max);
extend("required", required);
extend("email", email);
extend("min_value", min_value);
extend("max_value", max_value);
extend("alpha", alpha);
extend("alpha_dash", alpha_dash);
extend("alpha_spaces", alpha_spaces);

//Regras customizadas
import validacaoDataNascimento from "./helpers/validacaoDataNascimento";
import validacaoDataMaiorDeIdade from "./helpers/validacaoDataMaiorDeIdade";
import validacaoCPF from "./helpers/validacaoCPF";
import validacaoCNPJ from "./helpers/validacaoCNPJ";
import validacaoExpiracaoCartaoCredito from "./helpers/validacaoExpiracaoCartaoCredito";
import validacaoValorBike from "./helpers/validacaoValorBike";
import validacaoValorAcessorio from "./helpers/validacaoValorAcessorio";
import validacaoNomeCompleto from "./helpers/validacaoNomeCompleto";
import validacaoDataFimDesconto from "./helpers/validacaoDataFimDesconto";
import validacaoData from "./helpers/validacaoData";
import validacaoDataHoje from "./helpers/validacaoDataHoje";
import validacaoCupomDesconto from "./helpers/validacaoCupomDesconto";
import validacaoTelefone from "./helpers/validacaoTelefone";
import validacaoSenha from "./helpers/validacaoSenha";

extend("data_nascimento", validacaoDataNascimento);
extend("data_maior_idade", validacaoDataMaiorDeIdade);
extend("cpf", validacaoCPF);
extend("cnpj", validacaoCNPJ);
extend("expiracao_cartao", validacaoExpiracaoCartaoCredito);
extend("valor_bike", validacaoValorBike);
extend("valor_acessorio", validacaoValorAcessorio);
extend("nome_completo", validacaoNomeCompleto);
extend("data_fim_desconto", validacaoDataFimDesconto);
extend("data_valida", validacaoData);
extend("data_hoje", validacaoDataHoje);
extend("cupom", validacaoCupomDesconto);
extend("validacao_telefone", validacaoTelefone);
extend("password_rules", validacaoSenha);



import pt_BR from "vee-validate/dist/locale/pt_BR.json";
localize("pt_BR", pt_BR);
configure({
  classes: {
    // valid: 'is-valid',
    invalid: "is-invalid",
  },
});
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

//Sentry
//======================================================
// import * as Sentry from "@sentry/browser";
// import * as Integrations from "@sentry/integrations";

// Sentry.init({
//   dsn: "https://efc205b8113c440e926e24cca9b10e27@sentry.io/3661448",
//   integrations: [new Integrations.Vue({ Vue, attachProps: true })],
// });

//VueClipboard
//======================================================
import VueClipboard from "vue-clipboard2";
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

//Inicialização principal
//======================================================
Vue.config.productionTip = false;
Vue.config.ignoredElements = ["lottie-player"];

//Interceptor
//======================================================
import "./interceptor";

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
