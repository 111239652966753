import configProduction from "./config.production";
import listaBancos from "./helpers/listaBancos";

let config = {
  //  API_URLV2: "http://localhost:12300", //Local Dev
  API_URLV2: "https://api-app-dev.azurewebsites.net", //Local Dev
  //  API_URLV2: "https://apim-bikeregistrada.azure-api.net/dev",
  //  API_URLV2: "https://api-dev-bike.azurewebsites.net",
  //  API_URLV2: "https://api-bikeregistrada-v4-stage.azurewebsites.net", //Azure Stage

  CNPJBIKE: "19654238000169",
  TOKEN: "6LcAVGgpAAAAAAknM1UNPzJ9lzw7-nVdblJrWJaG",
  //SELO
  VALOR_SELO: 69.90,
  VALOR_FRETE: 12.00,

  //POLITICA PRIVACIDADE s

  POLITICA_PRIVACIDADE: "https://bikeregistrada.com.br/politicaprivacidade",

  FACEBOOK_APP_ID: "617658625526204",
  GOOGLE_ANALYTICS_ID: "AW-954482663",
  ENABLE_MARKETING_TRACKERS: false,

  WHATSAPP_BUTTON_PHONE: "5561996362383",
  WHATSAPP_BUTTON_TEXT:
    "Olá, acessei o site Seguro Bike Registrada e gostaria de mais informações.",

  ID_PLANO_URBANO: "Urbano",
  ID_PLANO_ATIVO: "Ativo",
  ID_PLANO_PERFORMANCE: "Performance",
  ID_PLANO_PRATA: "Prata",
  ID_PLANO_OURO: "Ouro",

  //TIPO ASSINATURA
  ID_TIPO_ASSINATURA_MENSAL: "e75ab49e-43f4-49bd-810a-784c1f063e0e",
  ID_TIPO_ASSINATURA_ANUAL: "76f0aa4a-5052-4c77-980a-86c33eff8a5a",
  ID_TIPO_ASSINATURA_SEMESTRAL: "97584d92-9e56-4f31-a889-40384d00080c",


  //VALOR MINIMO PLANOS
  VALOR_MINIMO_PLANO_URBANO: 17.39,
  VALOR_MINIMO_PLANO_ATIVO: 18.84,
  VALOR_MINIMO_PLANO_PERFORMANCE: 21.31,

  //SITUAÇÃO SEGURO
  SITUACAO_APROVADA: "fe29d037-57f1-42d7-b42f-f057ee0c189a",
  SITUACAO_ENVIADA: "520a8622-6a4d-4c9d-beee-5715e8e37f8d",
  SITUACAO_PENDENTE: "5b51ed49-d337-4b73-9d48-1fc9ce5fcd57",
  SITUACAO_REPROVADA: "31fd5a97-e2c3-4744-90a7-1b4456bd8a15",
  SITUACAO_CANCELADA: "0457e100-1e27-4182-b838-0181087a2579",
  SITUACAO_ENCERRADO: "8a321636-438c-42d0-98e6-cedd5e6ab20a",
  SITUACAO_PRE_ENVIADA: "f94a21f9-f0e2-457f-b891-e0a93f360c10",

  //STATUS REGISTRO
  STATUS_BIKE_LIBERADA: "1310CA76-D391-4A78-8F11-28A5D319E6B5",
  STATUS_BIKE_RECUPERADA: "C90F6AC8-3944-44AC-92EF-437D161ACD77",
  STATUS_BIKE_CANCELADA: "949248D5-3C54-4D9F-ABCE-476E93446FA2",
  STATUS_BIKE_TRANSFERENCIA_PENDENTE: "CFFDB9DB-62D3-44E7-B290-59E6913F8D3D",
  STATUS_BIKE_ROUBADA: "4AF0267E-8D3D-4513-8D5E-87BAEC31DC71",
  STATUS_BIKE_REGISTRADA: "3B65294F-B5B5-40CE-8108-AAAF221F7A7C",
  STATUS_BIKE_NAO_FINALIZADA: "9E8237A7-CF6B-4818-9478-B66F64D398A0",
  STATUS_BIKE_ASSINATURA_PENDENTE: "2A3AE0C6-B2BA-428F-8EC2-F15E7310BFAA",

  //STATUS REGISTRO
  STATUS_ASSINATURA_RENOVACAO_PENDENTE: "142bc815-6f50-4e8b-9955-4b8f76e86b07",
  STATUS_ASSINATURA_CANCELADA: "3289b28d-cb5a-4cd4-98d1-8286f26ca122",
  STATUS_ASSINATURA_ATIVA: "a5b69687-462a-47f6-be6e-3142574acac1",


  //STATUS INSPEC
  STATUS_INSPEC_AGUARDANDO: 0,
  STATUS_INSPEC_AVALIANDO: 1,
  STATUS_INSPEC_APROVADO: 2,
  STATUS_INSPEC_RECUSADO: 3,
  STATUS_INSPEC_VALOR_ALTERADO: 4,
  STATUS_INSPEC_DADOS_INCOMPLETOS: 5,

  //STATUS IUGU
  STATUS_FATURA_IUGU_PAGO: "paid",
  STATUS_FATURA_IUGU_PENDENTE: "pending",
  STATUS_FATURA_IUGU_REEMBOLSADA: "refunded",
  STATUS_FATURA_IUGU_CANCELADA: "canceled",

  //===MEMBER GET MEMBER ===
  RESGATE_TIPO_DINHEIRO: "D",
  RESGATE_TIPO_CUPOM: "C",
  SALDO_RESGATE_MEMBER_GET_MEMBER: 20.0,
  LISTA_BANCOS: listaBancos,
  FEATURE_FLAGS: {
    MEMBER_GET_MEMBER: true,
  },
  //STATUS DO PEDIDO RESGATE
  RESGATE_VALOR_MINIMO_DINHEIRO: 20,
  RESGATE_STATUS_ABERTO: 0,
  RESGATE_STATUS_CONCLUIDO: 1,
  UPLOAD_FILE_TYPES: ".jpg, .jpeg, .png, .pdf",
  //Descricao plano acessorio
  //Descrição dos Planos
  DESCRICAO_PLANO_ACESSORIO: { DESCRICAO: "Seguro para o acessório:" },
  //Descrição dos Planos
  DESCRICAO_PLANO_URBANO: [
    "Roubo e furto qualificado",
    "Assistência 24h",
    "Transporte e reboque",
  ],
  DESCRICAO_PLANO_ATIVO: [
    "Roubo e furto qualificado",
    "Quebra por acidente",
    "Assistência 24h",
    "Transporte e reboque",
    "Incêndio",
    "Queda de raio",
    "Explosão",
    "Colisão",
    "Danos decorrente de transporte",
  ],
  DESCRICAO_PLANO_PERFORMANCE: [
    "Roubo e furto qualificado",
    "Quebra por acidente",
    "Responsabilidade civil",
    "Assistência 24h",
    "Transporte e reboque",
    "Extensão para transporte internacional",
    "Incêndio",
    "Queda de raio",
    "Explosão",
    "Colisão",
    "Danos decorrente de transporte",
  ],
  DESCRICAO_PLANO_AP_OURO: [
    "Morte por acidente de bike",
    "Invalidez Permanente total ou parcial por Acidente de bike",
    "Despesas médico-hospitalares em decorrência de um acidente de bike",
  ],
  DESCRICAO_PLANO_AP_PRATA: [
    "Morte por acidente de bike",
    "Invalidez Permanente total ou parcial por Acidente de bike",
    "Despesas médico-hospitalares em decorrência de um acidente de bike",
  ],
  DESCRICAO_PLANO_AP_OURO_IDENIZACAO: "R$ 10.000,00",
  DESCRICAO_PLANO_AP_PRATA_IDENIZACAO: "R$ 5.000,00",

  MOTIVOS_CANCELAR_SEGURO_VALUES: {
    MUITO_CARO: "muito_caro",
    INSATISFEITO: "insatisfeito",
    OUTRA_SEGURADORA: "outra_seguradora",
    BIKE_ROUBADA: "bike_roubada",
    SEM_PEDALAR: "sem_pedalar",
    TROCAR_PLANO: "trocar_plano",
    BIKE_TROCADA: "bike_trocada",
    VALOR_APROVADO_ABAIXO_SIMULACAO: "valor_aprovado_abaixo_simulacao",
    BIKE_VENDIDA: "bike_vendida",
    SINISTRO_IDENIZADO: "sinistro_idenizado",
    DECLINIO_CLIENTE: "declinio_cliente",
    OUTROS: "outros",
  },

  MOTIVOS_CANCELAR_REGISTRO: [
    {
      value: 'vendida',
      text: "Bike vendida",
    },
    {
      value: 'trocada',
      text: "Bike trocada",
    },
    {
      value: 'doacao',
      text: "Doação",
    },
    {
      value: 'roubada_furtada',
      text: "Roubada/ Furtada",
    },
    {
      value: 'desistencia',
      text: "Desistência",
    },
    {
      value: 'financeiro',
      text: "Financeiro",
    },
    {
      value: 'insatisfacao',
      text: "Insatisfação",
    },
    {
      value: 'sem_uso',
      text: "Sem uso",
    },
    {
      value: 'outros',
      text: "Outros",
    },
  ],

  BIKE_VALOR_MINIMO: 1000,
  BIKE_VALOR_MAXIMO: 50000,
  ACESSORIO_VALOR_MINIMO: 100,
  ACESSORIO_VALOR_MAXIMO: 5000,

  IUGU_ACCOUNT: "84191965AAB94AA68302F424223286DF",
  IUGU_ACCOUNTB: "6E23EAECE0F24EC7988B1DD7F4449C14",
  IUGU_TEST_MODE: true,


  TELEFONE_ASSISTENCIA24H: "0800 567 0044",
  URL_MANUAL_SEGURO:
    "https://brimages.blob.core.windows.net/documentos/manual-cliente-seguro-essor_v2.pdf",
  URL_TERMOS_USO:
    "https://drive.google.com/file/d/146YUJ0xMCcAxjt0lsEW6HytkFQu4QHsG/view?usp=sharing",

  WHATSAPP_NUMERO: "556135224521",
  WHATSAPP_MENSAGEM_INICIAL:
    "Olá, acessei o sistema de Parceiros Bike Registrada e gostaria de mais informações.",
};

config.MOTIVOS_CANCELAR_SEGURO = [
  {
    value: config.MOTIVOS_CANCELAR_SEGURO_VALUES.MUITO_CARO,
    text: "Achei muito caro / Estou sem condições",
  },
  {
    value: config.MOTIVOS_CANCELAR_SEGURO_VALUES.DECLINIO_CLIENTE,
    text: "Declínio do cliente",
  },
  {
    value: config.MOTIVOS_CANCELAR_SEGURO_VALUES.INSATISFEITO,
    text: "Insatisfeito com o seguro",
  },
  {
    value: config.MOTIVOS_CANCELAR_SEGURO_VALUES.OUTRA_SEGURADORA,
    text: "Farei o seguro com outra seguradora",
  },
  {
    value: config.MOTIVOS_CANCELAR_SEGURO_VALUES.BIKE_ROUBADA,
    text: "Minha bike foi roubada",
  },
  {
    value: config.MOTIVOS_CANCELAR_SEGURO_VALUES.SEM_PEDALAR,
    text: "Não estou mais pedalando.",
  },
  {
    value: config.MOTIVOS_CANCELAR_SEGURO_VALUES.TROCAR_PLANO,
    text: "Quero trocar o plano de cobertura",
  },
  {
    value: config.MOTIVOS_CANCELAR_SEGURO_VALUES.BIKE_TROCADA,
    text: "Troquei minha bike",
  },
  {
    value:
      config.MOTIVOS_CANCELAR_SEGURO_VALUES.VALOR_APROVADO_ABAIXO_SIMULACAO,
    text: "Valor aprovado ficou abaixo do valor da simulação.",
  },
  {
    value: config.MOTIVOS_CANCELAR_SEGURO_VALUES.BIKE_VENDIDA,
    text: "Vendi a bike e vou ficar sem pedalar por um tempo.",
  },
  {
    value: config.MOTIVOS_CANCELAR_SEGURO_VALUES.SINISTRO_IDENIZADO,
    text: "Sinistro já idenizado.",
  },
  { value: config.MOTIVOS_CANCELAR_SEGURO_VALUES.OUTROS, text: "Outros" },
];


if (process.env.VUE_APP_ENV == "production") {
  config = Object.assign(config, configProduction);
}

export default config;
